import "./App.scss";
import React from "react";
import { useHistory } from "react-router-dom";
import { Switch, Route, Redirect } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import { Row, Col, Container, Spinner } from "react-bootstrap";
import { NavigationBar } from "./features/account/NavigationBar";
import { Footer } from "./features/account/Footer";
import { ProtectedRoute } from "./utils/ProtectedRoute";
import { Login } from "./features/auth/Login";
import { Expired } from "./features/auth/Expired";
import { Logout } from "./features/auth/Logout";
import { Signup } from "./features/auth/Signup";
import { Activate } from "./features/auth/Activate";
import { Shop } from "./features/account/Shop";
import { Success } from "./features/account/Success";
import { Converter } from "./features/core/Converter";
import { AddImage } from "./features/core/AddImage";
import { AddVideo } from "./features/core/AddVideo";
import { Gallery } from "./features/core/Gallery";
import { useAuth } from "./hooks/useAuth";
import { useWebSocket } from "./hooks/useWebSocket";
import { EditMediaList } from "./features/core/EditMediaList";
import { Fallback } from "./utils/Fallback";
import { Terms } from "./features/account/Terms";
import { Privacy } from "./features/account/Privacy";
import { Contacts } from "./features/account/Contacts";
import { Settings } from "./features/account/Settings";
import { Reset } from "./features/auth/Reset";
import { ResetConfirm } from "./features/auth/ResetConfirm";
import { Landing } from "./features/core/Landing";
import TagManager from "react-gtm-module";
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";
import config from "./config";
import { useState, useEffect } from "react";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { Potato } from "./features/account/Potato";
var classNames = require("classnames");

function App() {
  const [isAuthenticated, isLoading] = useAuth();
  const { push } = useHistory();
  const [tagManagerLoaded, setTagManagerLoaded] = useState(false);
  useWebSocket(isAuthenticated);

  const appClass = classNames({
    "app-container": true,
    grid: !isAuthenticated,
  });

  const paypalInitialOptions = {
    "client-id": config.paypal.clientId,
    "disable-funding": "card",
    intent: "authorize",
  };

  // Check cookie consent on mount. If already accepted, load Tag Manager immediately.
  useEffect(() => {
    if (getCookieConsentValue() === "true") {
      TagManager.initialize({ gtmId: config.tagManager.gtmId });
      setTagManagerLoaded(true);
    }
  }, []);

  const handleAcceptCookies = () => {
    if (!tagManagerLoaded) {
      TagManager.initialize({ gtmId: config.tagManager.gtmId });
      setTagManagerLoaded(true);
    }
  };

  const handleDeclineCookies = () => {
    // Optional: add any logic needed when cookies are rejected
  };

  return isLoading ? (
    <div className="loading-screen">
      <Spinner animation="border" variant="secondary" />
      <span className="my-3">Loading...</span>
    </div>
  ) : (
    <>
      <ErrorBoundary
        FallbackComponent={Fallback}
        onError={(error, errorInfo) => {}}
        onReset={() => {
          push("/");
        }}
      >
        <PayPalScriptProvider options={paypalInitialOptions}>
          <Container className={appClass} fluid>
            <Row className="gy-5">
              <NavigationBar>Wannafake</NavigationBar>
            </Row>
            {/* <Row className="mt-3">
          <Col className="px-0">
          <div className="alert alert-warning" role="alert">
          {"\u24D8"}&nbsp;&nbsp;
          {
            "Service intermittently unavailable today for maintenance. Thank you for your understanding."
          }
          </div>
          </Col>
        </Row> */}
            <Row>
              <Col className="p-0">
                <Switch>
                  {/* Landing page route handling */}
                  <Route
                    exact
                    path="/"
                    render={({ match }) => {
                      return isAuthenticated ? (
                        <Redirect to="/home" />
                      ) : (
                        <Landing />
                      );
                    }}
                  />

                  {/* Public-only routes (not accesible for auth users) */}
                  <ProtectedRoute
                    exact
                    isPublicOnly
                    path="/login"
                    render={() => {
                      return <Login />;
                    }}
                  />
                  <ProtectedRoute
                    exact
                    isPublicOnly
                    path="/signup"
                    render={() => <Signup />}
                  />
                  <ProtectedRoute
                    exact
                    isPublicOnly
                    path="/reset"
                    render={() => <Reset />}
                  />
                  <ProtectedRoute
                    exact
                    isPublicOnly
                    path="/activate/:userId/:token"
                    component={Activate}
                  />
                  <ProtectedRoute
                    exact
                    isPublicOnly
                    path="/reset/confirm/:userId/:token"
                    component={ResetConfirm}
                  />

                  {/* Private (auth) routes */}
                  <ProtectedRoute
                    exact
                    path="/potato"
                    render={() => <Potato />}
                  />
                  <ProtectedRoute
                    exact
                    path="/settings"
                    render={() => <Settings />}
                  />
                  <ProtectedRoute exact path="/shop" render={() => <Shop />} />
                  <ProtectedRoute
                    exact
                    path="/home"
                    render={() => <Converter />}
                  />
                  <ProtectedRoute
                    exact
                    path="/add-img"
                    render={() => <AddImage />}
                  />
                  <ProtectedRoute
                    exact
                    path="/add-vid"
                    render={() => <AddVideo />}
                  />
                  <ProtectedRoute
                    exact
                    path="/edit-img"
                    render={() => <EditMediaList type={"img"} />}
                  />
                  <ProtectedRoute
                    exact
                    path="/edit-vid"
                    render={() => <EditMediaList type={"vid"} />}
                  />
                  <ProtectedRoute
                    exact
                    path="/gallery"
                    render={() => <Gallery />}
                  />
                  <ProtectedRoute
                    exact
                    path="/logout"
                    render={() => <Logout />}
                  />

                  {/* Always-available routes */}
                  <Route exact path="/contacts" render={() => <Contacts />} />
                  <Route exact path="/expired" render={() => <Expired />} />
                  <Route exact path="/privacy" render={() => <Privacy />} />
                  <Route exact path="/success" render={() => <Success />} />
                  <Route exact path="/terms" render={() => <Terms />} />

                  {/* 404 */}
                  <Route
                    exact
                    path="/not-found"
                    render={() => <h3>Not found.</h3>}
                  />
                  <Redirect from="/" to="/not-found" />
                </Switch>
              </Col>
            </Row>
            <Row className="mt-auto">
              <Footer />
            </Row>
          </Container>
        </PayPalScriptProvider>
      </ErrorBoundary>

      <CookieConsent
        location="bottom"
        buttonText="Accept"
        declineButtonText="Reject"
        enableDeclineButton
        style={{ background: "#333" }}
        buttonStyle={{ background: "#f1d600", color: "#000" }}
        declineButtonStyle={{ background: "#fff", color: "#000" }}
        onAccept={handleAcceptCookies}
        onDecline={handleDeclineCookies}
      >
        We use cookies to enhance your experience. Click 'Accept' to allow
        cookies or 'Reject' to decline.
      </CookieConsent>
    </>
  );
}

export default App;
